<script lang="ts">
import { PropType, h } from 'vue'

export default {
  props: {
    level: {
      type: Number,
      default: 1,
    },
    mode: {
      type: String as PropType<'light' | 'dark'>,
      default: 'light',
    },
  },
  setup(props, { slots }) {
    const fontSizeMap: { [key: number]: string } = {
      1: 'tw-text-4xl tw-font-bold',
      2: 'tw-text-3xl tw-font-bold',
      3: 'tw-text-2xl tw-font-bold',
      4: 'tw-text-base md:tw-text-xl tw-font-semibold',
      5: 'tw-text-base md:tw-text-lg tw-font-semibold',
      6: 'tw-text-base tw-font-semibold',
    }

    const colorMap: { [key: string]: string } = {
      light: 'tw-text-gray-800',
      dark: 'tw-text-white',
    }

    return () =>
      h(
        `h${props.level}`,
        { class: `${fontSizeMap[props.level]} tw-font-inter ${colorMap[props.mode]}` },
        slots.default?.(),
      )
  },
}
</script>
